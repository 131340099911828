//Australian Icons
import Moose from '../../assets/images/clients/australia/Moose.svg';
import Mates from '../../assets/images/clients/australia/Mates.svg';
import One from '../../assets/images/clients/australia/111.svg';
import Birch from '../../assets/images/clients/australia/Birch-Building.svg';
import BrisbaneMouthGuards from '../../assets/images/clients/australia/Brisbane-Mouthguards.svg';
import BrisbaneValplast from '../../assets/images/clients/australia/Brisbane-Valplast.svg';
import BrothersRugby from '../../assets/images/clients/australia/Brothers-Rugby-SC.svg';
import ChoiceMedia from '../../assets/images/clients/australia/Choice-Media.svg';
import Dalgety from '../../assets/images/clients/australia/Dalgety.svg';
import OMSFM from '../../assets/images/clients/australia/OMSFM.svg';
import QLD from '../../assets/images/clients/australia/QLD.svg';
import SafewayDigital from '../../assets/images/clients/australia/Safeway-Digital.svg';
import VTG from '../../assets/images/clients/australia/VTG.svg';

//European Icons
import BleLines from '../../assets/images/clients/europe/Ble-Lines.svg';
import Recpal from '../../assets/images/clients/europe/Recpal.svg';

//Asian Icons
import Adroit from '../../assets/images/clients/asia/Adroit.svg';
import ANA from '../../assets/images/clients/asia/ANA.svg';
import Anyboli from '../../assets/images/clients/asia/Anyboli.svg';
import BW from '../../assets/images/clients/asia/B&W.svg';
import Creation from '../../assets/images/clients/asia/Creation.svg';
import Denvax from '../../assets/images/clients/asia/Denvax.svg';
import Exim from '../../assets/images/clients/asia/Exim.svg';
import Istana from '../../assets/images/clients/asia/Istana.svg';
import JKLM from '../../assets/images/clients/asia/JKLM.svg';
import KailasSadan from '../../assets/images/clients/asia/Kailas-Sadan.svg';
import Mystsriya from '../../assets/images/clients/asia/Mystsriya.svg';
import ReliefCounselling from '../../assets/images/clients/asia/Relief-Counselling.svg';
import Sampann from '../../assets/images/clients/asia/Sampann.svg';
import SNK from '../../assets/images/clients/asia/SNK.svg';
import SVR from '../../assets/images/clients/asia/SVR.svg';
import Taaseer from '../../assets/images/clients/asia/Taaseer.svg';
import Vibha from '../../assets/images/clients/asia/Vibha.svg';
import Young15 from '../../assets/images/clients/asia/Young-15.svg';

//MENA Icons
import Timebag from '../../assets/images/clients/mena/Timebag.svg';
import Tpp from '../../assets/images/clients/mena/TPP.svg';

import ClientSlider from '../../components/home/clientSlider';

export default function Clients() {
  const slides = [
    {
      australia: [
        One,
        Birch,
        BrisbaneMouthGuards,
        BrisbaneValplast,
        BrothersRugby,
        ChoiceMedia,
        Dalgety,
        OMSFM,
        QLD,
        SafewayDigital,
        VTG,
        Moose,
        Mates,
      ],
      europe: [BleLines, Recpal],
      asia: [
        Adroit,
        ANA,
        Anyboli,
        BW,
        Creation,
        Denvax,
        Exim,
        Istana,
        JKLM,
        KailasSadan,
        Mystsriya,
        ReliefCounselling,
        Sampann,
        SNK,
        SVR,
        Taaseer,
        Vibha,
        Young15,
      ],
      mena: [Timebag, Tpp],
      america: [
        One,
        Birch,
        BrisbaneMouthGuards,
        BrisbaneValplast,
        BrothersRugby,
        ChoiceMedia,
        Dalgety,
      ],
    },
  ];

  return (
    <div className="h-screen bg-black px-5 lg:px-10 py-5">
      <h6 className="text-white text-[22px] lg:text-[1.6em] font-bold font-lato">
        We Serve Clients Across The World
      </h6>
      <p className="text-[#8D8E8E] py-2 font-lato text-[14px] lg:text-[1em]">
        Our motto: Do all you can, do the best you can. Leave the rest to higher
        hands.
      </p>

      {/* Mobile View */}
      <div className="block lg:hidden flex flex-col gap-y-3">
        {['europe', 'asia', 'america', 'mena', 'australia'].map(
          (region, index) => (
            <div
              key={index}
              className="flex flex-col lg:gap-y-0 lg:flex-row lg:items-center"
            >
              <div className="flex flex-col py-2">
                <div className="text-white text-[1em] py-1 font-lato">
                  {index === 3
                    ? region.toUpperCase()
                    : region.charAt(0).toUpperCase() + region.slice(1)}
                </div>
                <div className="flex lg:flex-wrap gap-x-4 py-1">
                  {slides[0][region].length < 5 ? (
                    slides[0][region].map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        alt={`${region}-client-${i}`}
                        className="w-[4.5em]"
                      />
                    ))
                  ) : (
                    <ClientSlider country={region} />
                  )}
                </div>
              </div>
              <div className="flex lg:hidden h-[2px] w-full bg-[#2584FC]"></div>
            </div>
          )
        )}
      </div>

      {/* Desktop View */}
      <div className="hidden my-10 gap-y-4 lg:flex flex-col">
        {['europe', 'asia', 'america', 'mena', 'australia'].map(
          (region, index) => (
            <div key={index} className="w-full flex flex-col">
              <div className="flex justify-between items-center py-2">
                <div className="text-white text-[1em] font-lato w-1/4">
                  {index === 3
                    ? region.toUpperCase()
                    : region.charAt(0).toUpperCase() + region.slice(1)}
                </div>
                <div className="hidden lg:flex lg:flex-wrap gap-x-4 w-3/4">
                  {slides[0][region].length < 9 ? (
                    slides[0][region].map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        alt={`${region}-client-${i}`}
                        className="w-[4.5em]"
                      />
                    ))
                  ) : (
                    <ClientSlider country={region} />
                  )}
                </div>
              </div>
              <div className="hidden lg:flex h-[2px] w-full bg-[#2584FC]"></div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
