import Card from '../../atoms/home/card';
import ArrowRightIcon from '../../assets/icons/arrowRight.svg';

export default function About() {
  const cards = [
    {
      text1: `PROVEN TRACK RECORD`,
      text2: `Over`,
      text3: `50+`,
      text4: `Companies`,
      text5: `have partnered with us for solutions that deliver lasting results.`,
    },
    {
      text1: `YEARS OF EXPERTISE`,
      text2: `With over`,
      text3: `10`,
      text4: `Years`,
      text5: `of industry experience, we bring seasoned knowledge to every project.`,
    },
    {
      text1: `DELIVERING SUCCESS`,
      text2: `More than`,
      text3: `100`,
      text4: `Projects`,
      text5: `completed, building trust and exceeding expectations across industries.`,
    },
  ];

  return (
    <div className="no-scrollbar bg-black h-screen px-5 py-5 lg:py-10 lg:px-10">
      <div className="flex justify-between">
        <h3 className="text-white text-[22px] lg:text-[1.5em] font-lato">
          Trusted by 50+ startups and organizations just like yours
        </h3>
      </div>
      <p className="text-[#8D8E8E] py-2 px-1 font-lato text-[14px] lg:text-[18px]">
        {/* Book a <span className="font-[600]">30-min</span> no obligation casual
        discovery call to explore synergies.{' '} */}
        With years of experience, delivering trusted and reliable results.
      </p>

      <div className="flex flex-col justify-center my-5 lg:my-0">
        <div className="flex gap-x-4 overflow-x-auto py-10 lg:justify-center lg:items-center w-full no-scrollbar">
          {cards.map((card, index) => (
            <div key={index} className="flex-shrink-0">
              <Card
                borderColor="text-white hover:text-black glow-box mx-auto transition-all duration-1000 ease-in-out border-white"
                backgroundColor="hover:bg-bgNav text-black"
                textColor=""
                cardText={card}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
