export default function NextStepsDesktop() {
  return (
    <div className="relative">
      <div className="hidden lg:flex absolute h-[30em] left-[40px] right-0 border-[2px] w-full rounded-2xl border-dotted border-[#397AFA]"></div>
      <div className="relative z-10 top-0 hidden lg:block bg-[#1F1F1F] rounded-[20px] py-10 px-10">
        <h6 className="font-lato text-white text-[1.5em] font-[400] uppercase rounded-[20px]">
          What Happens Next ?
        </h6>
        <div className="relative flex flex-col gap-y-5 mt-4">
          <div className="absolute top-[10%] h-[90%] left-[30px] w-[2px] bg-[#2584FC]"></div>
          <div className="flex gap-x-2 items-center">
            <div className="relative w-[15%]">
              <div className="z-10 border-[2px] border-[#2584FC] bg-[#D9D9D9] font-lato text-[38px] h-[60px] w-[60px] justify-center font-[400] p-2 rounded-full flex items-center">
                1
              </div>
            </div>
            <div className="w-[85%] text-white font-lato text-[1em] font-[300]">
              Having received and processed your request, we will get back to
              you shortly to detail your project needs and sign an NDA to ensure
              the confidentiality of information.
            </div>
          </div>
          <div className="flex gap-x-2 items-center">
            <div className="relative w-[15%]">
              <div className="z-10 border-[2px] border-[#2584FC] bg-[#D9D9D9] font-lato text-[38px] justify-center h-[60px] w-[60px] font-[400] p-2 rounded-full flex items-center">
                2
              </div>
            </div>
            <div className="w-[85%] text-white font-lato text-[1em] font-[300]">
              After examining requirements, our analysts and developers devise a
              project proposal with the scope of works, team size, time and cost
              estimates.
            </div>
          </div>
          <div className="flex gap-x-2 items-center">
            <div className="relative w-[15%]">
              <div className="z-10 border-[2px] border-[#2584FC] bg-[#D9D9D9] font-lato text-[38px] justify-center h-[60px] w-[60px] font-[400] p-2 rounded-full flex items-center">
                3
              </div>
            </div>
            <div className="w-[85%] text-white font-lato text-[1em] font-[300]">
              We arrange a meeting with you to discuss the offer and come to an
              agreement.
            </div>
          </div>
          <div className="flex gap-x-2 items-center">
            <div className="relative w-[15%]">
              <div className="z-10 border-[2px] border-[#2584FC] bg-[#D9D9D9] font-lato justify-center text-[38px] h-[60px] w-[60px] font-[400] p-2 rounded-full flex items-center">
                4
              </div>
            </div>
            <div className="w-[85%] text-white font-lato text-[1em] font-[300]">
              We sign a contract and start working on your project as quickly as
              possible.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
