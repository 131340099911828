import WhatsAppIcon from '../../assets/icons/whatsAppIcon.svg';
import ArrowRightIcon from '../../assets/icons/arrowRight.svg';
import MessageIcon from '../../assets/icons/message.svg';

export default function Chat() {
  return (
    <div className="flex flex-col bg-black h-screen lg:h-fit px-5 py-5 lg:px-0 lg:flex-row lg:items-center">
      <div className="lg:w-[45%]">
        <h6 className="font-lato font-[500] text-white text-[30px] lg:text-[48px]">
          Let’s Chat <br />
          We Respond Fast
        </h6>
      </div>
      <div className="my-24 lg:my-0 flex flex-col lg:flex-row justify-center lg:justify-end">
        <div className="flex flex-col gap-y-10 justify-center h-full lg:flex-row lg:gap-x-2">
          <a
            href="https://wa.me/919911800399"
            target="_blank"
            className="bg-bgCTA cursor-pointer flex flex-col gap-y-4 rounded-xl py-5 lg:py-10 px-5 w-full lg:min-w-[310px] border-[1px] border-white"
          >
            <img src={WhatsAppIcon} className="w-[40px] lg:w-[50px]"></img>
            <div className="flex justify-between mt-5 lg:mt-0">
              <p className="text-textColor4 font-lato text-[14px] lg:text-[22px]">
                Whatsapp Us
              </p>
              <img src={ArrowRightIcon} className="w-[14px] w-fit"></img>
            </div>
          </a>
          <a href="mailto:buildatscale@gmail.com">
            <div className="bg-bgCTA cursor-pointer flex flex-col gap-y-4 rounded-xl py-4 lg:py-10 px-5 w-full lg:min-w-[310px] border-[1px] border-white">
              <img src={MessageIcon} className="w-[40px] lg:w-[50px]"></img>
              <div className="flex justify-between mt-5 lg:mt-0">
                <p className="text-textColor4 font-lato text-[14px] lg:text-[22px]">
                  Send Us Email
                </p>
                <img src={ArrowRightIcon} className="w-[14px] w-fit"></img>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
