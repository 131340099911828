export default function Service() {
  return (
    <div className="no-scrollbar font-lato bg-black h-screen  px-5 py-5 lg:py-10 lg:px-10">
      <h6 className="text-white text-[22px] lg:text-[1.5em] font-[600]">
        Our services
      </h6>
      <p className="text-textColor py-2 font-[100] text-[14px] lg:text-[18px] font-[400]">
        Tailored solutions designed to elevate your business and drive growth
      </p>
      <div className="flex gap-y-6 lg:gap-x-4 bg-black flex-col lg:flex-row mt-4 lg:pb-10 lg:mt-10">
        <div className="card lg:w-1/2 bg-black h-[250px] lg:min-h-[400px] rounded-md lg:pb-5 lg:px-5">
          <div className="card-content relative">
            <h6 className="text-gradient font-[900] font-inter text-[2.5em] w-full lg:text-[5.5em]">
              build strong
            </h6>
            <div className="top-[50px] absolute lg:top-[110px] px-10 lg:px-4">
              <p className="text-[#24FFFF] text-[25px] lg:text-[35px] font-[600]">
                Digital Platforms
              </p>
              <div className="flex flex-col mt-5">
                <div className="flex flex-col lg:gap-y-2">
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Landing Pages
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Websites
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Mobile Apps
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Saas
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card relative bg-black h-[250px] lg:min-h-[400px] rounded-md lg:w-1/2 py-2 pb-10 lg:pb-5">
          <div className="card-content relative">
            <h6 className="text-gradient font-[700] font-inter text-white text-[2em] lg:text-[5.5em] font-[900]">
              scale fast
            </h6>
            <div className="top-[50px] shadow-lg absolute lg:top-[110px] px-10 lg:px-4">
              <p className="text-[#24FFFF] text-[25px] lg:text-[35px] font-[600]">
                Marketing Solutions
              </p>
              <div className="flex flex-col mt-5">
                <div className="flex flex-col lg:gap-y-2">
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Top of the Funnel Consultations
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Webinar Marketing
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Performance Marketing
                  </p>
                  <p className="text-white font-[600] text-[16px] lg:text-[22px]">
                    Social Media Marketing Plan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
