import ArrowRightIcon from '../../assets/icons/arrowRight.svg';

export default function BlogCard({ link, title, description, date, image }) {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const plainTextContent = description.replace(/<[^>]+>/g, ''); // Remove HTML tags

  return (
    <a
      href={link}
      target="_blank"
      className="cursor-pointer rounded-[20px] lg:px-6 lg:py-6 w-full px-4 w-[20em] lg:w-[25em] flex flex-col gap-y-1 lg:gap-y-2 lg:border-[1.5px] lg:border-primary flex-shrink-0"
    >
      <img
        src={image}
        className="rounded-[20px] w-full h-[10em] lg:h-[12em] object-fit"
      ></img>
      <h6 className="font-lato text-white mt-5 text-[18px] lg:text-[22px] font-[700]">
        {title}
      </h6>
      <p className="text-[12px] text-gray-500">{formattedDate}</p>
      <p className="description font-lato h-[4em] font-[300] text-white text-[16px]">
        {plainTextContent}
      </p>
      <div className="flex items-center gap-x-2 mt-2">
        <a href={link} className="text-white text-[16px] font-lato font-bold">
          Read more
        </a>
        <div>
          <img src={ArrowRightIcon} className="w-[12px]"></img>
        </div>
      </div>
    </a>
  );
}
