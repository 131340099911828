import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Blog from './pages/blog';
import BlogIndividual from './pages/blogIndividual';
import WallOfFame from './pages/wallOfFame';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/blog/" element={<Blog />} />
          <Route path="/fame/" element={<WallOfFame />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
