import { useState, useRef } from 'react';
import Mic from '../../assets/icons/mic.svg';
import Upload from '../../assets/icons/upload.svg';
import { TiArrowSortedUp } from 'react-icons/ti';

export default function ContactForm() {
  const [isDropdownProject, setIsDropdownProject] = useState(false);
  const [selectedOptionProject, setSelectedOptionProject] = useState(
    'Project Budget (Min $15k)'
  );
  const [isDropdownRequirement, setIsDropdownRequirement] = useState(false);
  const [selectedOptionRequirement, setSelectedOptionRequirement] = useState(
    'Select your requirement'
  );

  const requirements = [
    'UX/UI',
    'Landing Page',
    'Website Development',
    'Custom Software Development',
    'Performance Marketing',
    'Consulting',
    'Collaboration',
  ];
  const [formValues, setFormValues] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    subject: '',
    description: '',
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);

  const toggleDropdownProject = () => {
    setIsDropdownProject(!isDropdownProject);
  };

  const toggleDropdownRequirement = () => {
    setIsDropdownRequirement(!isDropdownRequirement);
  };

  const handleOptionProject = option => {
    setSelectedOptionProject(option);
    setIsDropdownProject(false);
  };

  const handleOptionRequirement = option => {
    setSelectedOptionRequirement(option);
    setIsDropdownRequirement(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formValues).forEach(key => {
      if (!formValues[key]) {
        errors[key] = true;
      }
    });
    setValidationErrors(errors);
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateForm();
    // You can add form submission logic here
  };

  const startRecording = async () => {
    setIsRecording(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.start();

    mediaRecorderRef.current.ondataavailable = event => {
      setAudioBlob(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      setIsRecording(false);
      // You can handle the audio blob here (e.g., upload or play it)
    };
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <form className="flex flex-col gap-y-2 mx-auto" onSubmit={handleSubmit}>
      <input
        className={`border-[1px] ${validationErrors.name ? 'border-red-500' : 'border-borderColor'} text-xs text-xs pl-5 bg-bgInput text-white w-full rounded-[15px] p-2`}
        placeholder="Name"
        aria-label="Name"
        name="name"
        value={formValues.name}
        onChange={handleInputChange}
      ></input>
      <div className="flex gap-x-4">
        <input
          className={`border-[1px] ${validationErrors.companyName ? 'border-red-500' : 'border-borderColor'} text-xs pl-5 bg-bgInput text-white w-1/2 rounded-[15px] p-2`}
          placeholder="Company name"
          aria-label="Company name"
          name="companyName"
          value={formValues.companyName}
          onChange={handleInputChange}
        ></input>
        <input
          className={`border-[1px] ${validationErrors.email ? 'border-red-500' : 'border-borderColor'} text-xs pl-5 bg-bgInput text-white w-1/2 rounded-[15px] p-2`}
          placeholder="Email"
          aria-label="Email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
        ></input>
      </div>
      <div className="flex gap-x-4">
        <input
          className={`border-[1px] ${validationErrors.phone ? 'border-red-500' : 'border-borderColor'} text-xs pl-5 bg-bgInput text-white w-1/2 rounded-[15px] p-2`}
          placeholder="Phone"
          aria-label="Phone"
          name="phone"
          value={formValues.phone}
          onChange={handleInputChange}
        ></input>
        <input
          className={`border-[1px] ${validationErrors.subject ? 'border-red-500' : 'border-borderColor'} text-xs pl-5 bg-bgInput text-white w-1/2 rounded-[15px] p-2`}
          placeholder="Subject"
          aria-label="Subject"
          name="subject"
          value={formValues.subject}
          onChange={handleInputChange}
        ></input>
      </div>
      <div className="relative z-[200]">
        <div
          className="absolute text-white text-xs pl-5 bg-bgInput rounded-[15px] border-[1px] border-borderColor p-2 w-full cursor-pointer"
          onClick={toggleDropdownRequirement}
        >
          <div className="flex items-center text-textColor2 justify-between">
            {selectedOptionRequirement}
            {isDropdownRequirement ? (
              <TiArrowSortedUp />
            ) : (
              <TiArrowSortedUp className="rotate-180" />
            )}
          </div>
          {isDropdownRequirement && (
            <div className="mt-2 z-[100]">
              {requirements.map(requirement => {
                return (
                  <div
                    className="text-textColor2 py-2 cursor-pointer"
                    onClick={() => handleOptionRequirement(requirement)}
                  >
                    {requirement}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <textarea
        rows={1} // Default for mobile
        placeholder="Describe your requirement"
        className={`text-white bg-bgInput mt-8 text-xs pl-4 rounded-[15px] border-[1px] ${validationErrors.description ? 'border-red-500' : 'border-borderColor'} p-2 w-full lg:rows-[4]`} // Use lg:rows-[4] for larger screens
        aria-label="Describe your requirement"
        name="description"
        value={formValues.description}
        onChange={handleInputChange}
      ></textarea>

      <div className="relative z-[150]">
        <div
          className="absolute text-white text-xs pl-5 bg-bgInput rounded-[15px] border-[1px] border-borderColor p-2 w-full cursor-pointer"
          onClick={toggleDropdownProject}
        >
          <div className="flex items-center text-textColor2 justify-between">
            {selectedOptionProject}
            {isDropdownProject ? (
              <TiArrowSortedUp />
            ) : (
              <TiArrowSortedUp className="rotate-180" />
            )}
          </div>
          {isDropdownProject && (
            <div className="mt-2 z-[150]">
              <div
                className="text-textColor2 py-2 cursor-pointer"
                onClick={() => handleOptionProject('Project Budget (Min $15k)')}
              >
                $15K-$30K
              </div>
              <div
                className="text-textColor2 py-2 cursor-pointer"
                onClick={() => handleOptionProject('$15K-$30K')}
              >
                $15K-$30K
              </div>
              <div
                className="text-textColor2 py-2 cursor-pointer"
                onClick={() => handleOptionProject('$15K-$30K')}
              >
                $15K-$30K
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex mt-8 flex-row gap-x-2">
        <div className="flex relative flex-col w-1/2 items-center gap-y-2 bg-bgInput border-[1px] border-borderColor p-3 lg:p-6 rounded-[10px]">
          <p className="text-textColor3 h-[4em] lg:h-[2em] text-[0.75em] lg:h-[4em] font-lato text-center">
            Record a voice message about your project to help us understand it
            better
          </p>
          <div
            className="bg-primary cursor-pointer w-[70%] lg:w-full flex items-center justify-center px-4 py-2 rounded-[10px]"
            onClick={isRecording ? stopRecording : startRecording}
          >
            <img src={Mic} alt="Mic" className="h-[16px] lg:h-[24px]" />
            {/* {isRecording ? ' Stop Recording' : ' Start Recording'} */}
          </div>
        </div>
        <div className="flex relative flex-col w-1/2 items-center gap-y-2 bg-bgInput p-3 lg:p-6 border-[1px] border-borderColor rounded-[10px]">
          <p className="text-textColor2 h-[4em] lg:h-[2em] text-[0.75em] lg:h-[4em] text-center pb-0 lg:pb-5">
            Attach additional documents as needed
          </p>
          <div
            className="bg-primary cursor-pointer w-[70%] lg:w-full flex items-center justify-center px-4 py-2 rounded-[10px]"
            onClick={() => document.getElementById('fileInput').click()}
          >
            <img
              src={Upload}
              alt="Upload Documents"
              className="h-[16px] lg:h-[24px]"
            />
          </div>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={e => console.log(e.target.files)} // Handle the file selection here
          />
        </div>
      </div>

      <div className="flex flex-col items-center gap-y-2 lg:flex-row lg:gap-x-2">
        <p className="text-white text-[0.75em] font-lato">
          Please be informed that when you click the Send button BuildAtScale
          will process your personal data in accordance with our 
          <a className="underline">Privacy Policy</a> for the purpose of
          providing you with appropriate information.
        </p>
        <button
          type="submit"
          className="bg-primary max-w-[8em] font-lato text-[0.75em] font-bold text-white w-full rounded-full p-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
