import blog from '../../assets/images/blog.png';
import BlogGridCard from '../../atoms/home/blogGridCard';

export default function BlogGrid() {
  return (
    <div className="px-10 bg-bgBlog h-screen">
      <div className="bg-white flex flex-col-reverse lg:flex-row rounded-md px-5 lg:pl-10 lg:pr-4 flex gap-x-4 py-10">
        <div className="lg:w-[50%] flex flex-col gap-y-2 mt-2 lg:mt-0 lg:gap-y-4">
          <h6 className="font-[700] font-lato font-[22px] lg:text-[40px] w-[70%]">
            This is the new age of development
          </h6>
          <p className="font-lato font-[400] text-[12px] lg:text-[16px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            et odio vel dui laoreet tempor. Cras pulvinar neque mi, vel rutrum
            ex feugiat eu. Etiam nec elit neque. Mauris nec gravida orci.{' '}
          </p>
          <button className="bg-primary text-white py-2 px-4 lg:px-8 lg:py-4 rounded-[30px] w-fit text-[12px] lg:text-[16px]">
            Read more
          </button>
        </div>
        <div className="lg:w-[50%] flex justify-end">
          <img src={blog} className="rounded-xl"></img>
        </div>
      </div>
      <div className="py-10">
        <div className="flex gap-x-4">
          <div className="bg-bgFilter cursor-pointer px-6 py-2 text-[12px] lg:text-[18px] rounded-full">
            All
          </div>
          <div className="bg-bgFilter cursor-pointer px-6 py-2 text-[12px] lg:text-[18px] rounded-full">
            Blog one
          </div>
          <div className="bg-bgFilter cursor-pointer px-6 py-2 text-[12px] lg:text-[18px] rounded-full">
            Blog two
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        <BlogGridCard />
        <BlogGridCard />
        <BlogGridCard />
        <BlogGridCard />
        <BlogGridCard />
        <BlogGridCard />
        <BlogGridCard />
      </div>
    </div>
  );
}
