import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import Heart from '../../assets/svgs/heart.svg';

const InstagramProfileEmbed = () => {
  return (
    <div>
      <div className="flex gap-2 items-center justify-center">
        <p className="font-lato  font-[700] text-[22px] lg:text-[40px]">
          Wall Of
        </p>
        <img src={Heart} className="w-[22px] lg:w-[40px]"></img>
      </div>
      <InstagramEmbed
        url="https://www.instagram.com/apple/"
        className="w-full mt-10"
      />
    </div>
  );
};

export default InstagramProfileEmbed;
