import BlogGrid from '../components/blog/blogGrid';
import Navbar from '../components/common/navbar';

export default function Blog() {
  return (
    <div className="">
      <Navbar />
      <BlogGrid />
    </div>
  );
}
