import React, { useEffect, useState } from 'react';
import Moose from '../../assets/images/clients/australia/Moose.svg';
import Mates from '../../assets/images/clients/australia/Mates.svg';
import One from '../../assets/images/clients/australia/111.svg';
import Birch from '../../assets/images/clients/australia/Birch-Building.svg';
import BrisbaneMouthGuards from '../../assets/images/clients/australia/Brisbane-Mouthguards.svg';
import BrisbaneValplast from '../../assets/images/clients/australia/Brisbane-Valplast.svg';
import BrothersRugby from '../../assets/images/clients/australia/Brothers-Rugby-SC.svg';
import ChoiceMedia from '../../assets/images/clients/australia/Choice-Media.svg';
import Dalgety from '../../assets/images/clients/australia/Dalgety.svg';
import OMSFM from '../../assets/images/clients/australia/OMSFM.svg';
import QLD from '../../assets/images/clients/australia/QLD.svg';
import SafewayDigital from '../../assets/images/clients/australia/Safeway-Digital.svg';
import VTG from '../../assets/images/clients/australia/VTG.svg';

// European Icons
import BleLines from '../../assets/images/clients/europe/Ble-Lines.svg';
import Recpal from '../../assets/images/clients/europe/Recpal.svg';

// Asian Icons
import Adroit from '../../assets/images/clients/asia/Adroit.svg';
import ANA from '../../assets/images/clients/asia/ANA.svg';
import Anyboli from '../../assets/images/clients/asia/Anyboli.svg';
import BW from '../../assets/images/clients/asia/B&W.svg';
import Creation from '../../assets/images/clients/asia/Creation.svg';
import Denvax from '../../assets/images/clients/asia/Denvax.svg';
import Exim from '../../assets/images/clients/asia/Exim.svg';
import Istana from '../../assets/images/clients/asia/Istana.svg';
import JKLM from '../../assets/images/clients/asia/JKLM.svg';
import KailasSadan from '../../assets/images/clients/asia/Kailas-Sadan.svg';
import Mystsriya from '../../assets/images/clients/asia/Mystsriya.svg';
import ReliefCounselling from '../../assets/images/clients/asia/Relief-Counselling.svg';
import Sampann from '../../assets/images/clients/asia/Sampann.svg';
import SNK from '../../assets/images/clients/asia/SNK.svg';
import SVR from '../../assets/images/clients/asia/SVR.svg';
import Taaseer from '../../assets/images/clients/asia/Taaseer.svg';
import Vibha from '../../assets/images/clients/asia/Vibha.svg';
import Young15 from '../../assets/images/clients/asia/Young-15.svg';

// MENA Icons
import Timebag from '../../assets/images/clients/mena/Timebag.svg';
import Tpp from '../../assets/images/clients/mena/TPP.svg';

import { motion } from 'framer-motion';

export default function ClientSlider({ country }) {
  const slidesCountry = {
    australia: [
      Moose,
      Mates,
      One,
      Birch,
      BrisbaneMouthGuards,
      BrisbaneValplast,
      BrothersRugby,
      ChoiceMedia,
      Dalgety,
      OMSFM,
      QLD,
      SafewayDigital,
      VTG,
    ],
    europe: [BleLines, Recpal],
    asia: [
      Adroit,
      ANA,
      Anyboli,
      BW,
      Creation,
      Denvax,
      Exim,
      Istana,
      JKLM,
      KailasSadan,
      Mystsriya,
      ReliefCounselling,
      Sampann,
      SNK,
      SVR,
      Taaseer,
      Vibha,
      Young15,
    ],
    mena: [Timebag, Tpp],
    america: [
      One,
      Birch,
      BrisbaneMouthGuards,
      BrisbaneValplast,
      BrothersRugby,
      ChoiceMedia,
      Dalgety,
    ],
  };

  const slides = country
    ? slidesCountry[country] || []
    : Object.values(slidesCountry).flat();

  // Duplicate slides for continuous scrolling
  const duplicatedSlides = [...slides, ...slides];

  const [slideWidth, setSlideWidth] = useState('100%');
  const [sliderWidth, setSliderWidth] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      const width =
        window.innerWidth >= 1024
          ? `${100 / duplicatedSlides.length}%`
          : `${300 / duplicatedSlides.length}%`;
      const sliderWidth = window.innerWidth >= 1024 ? 10 : 25;
      setSliderWidth(sliderWidth);
      setSlideWidth(width);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [duplicatedSlides.length]);

  return (
    <div className="relative w-full overflow-hidden">
      <motion.div
        className="flex"
        animate={{
          x: ['0%', '-50%'],
          transition: {
            ease: 'linear',
            duration: 120,
            repeat: Infinity,
            repeatType: 'loop',
          },
        }}
        style={{ width: `${duplicatedSlides.length * sliderWidth}%` }} // Ensure width accommodates all slides
      >
        {duplicatedSlides.map((slide, index) => (
          <div
            key={index}
            className="z-[100] mx-2"
            style={{ width: slideWidth }}
          >
            <img
              src={slide}
              alt={`client-logo-${index}`}
              className="w-auto h-[2em]"
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
}
