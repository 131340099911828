export default function Calendar() {
  return (
    <div className="h-screen bg-black py-5 flex flex-col gap-y-2 w-screen">
      <div className="px-5 lg:px-10">
        <h6 className="text-white font-lato font-[600] text-[22px] lg:text-[1.5em]">
          Take Action
        </h6>
        <p className="text-[#8D8E8E] py-2 font-lato text-[14px] lg:text-[18px]">
          Book a <span className="font-[600]">30-min</span> no obligation casual
          discovery call to explore synergies.{' '}
        </p>
      </div>
      <iframe
        src="https://cal.com/sarthaksikdar/40min-strategy?theme=dark"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
}
