import React, { useRef, useEffect, useState } from 'react';
import BlogCard from '../../atoms/home/blogCard';
import axios from 'axios';

export default function Blog() {
  const scrollRef = useRef(null);
  const cardWidth = 300; // Width of each card in pixels
  const [isHovered, setIsHovered] = useState(false); // To pause on hover
  const [blogs, setBlogs] = useState([]);
  const { REACT_APP_BLOG_API_ENDPOINT } = process.env;
  const handleBlogs = async () => {
    const response = await axios.get(REACT_APP_BLOG_API_ENDPOINT);
    const blogsData = response.data;
    setBlogs(blogsData);
  };

  useEffect(() => {
    handleBlogs();
  }, []);

  // Auto-scroll logic
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
        const maxScroll = scrollWidth - clientWidth;

        // If reached the end, scroll back to the start
        if (scrollLeft >= maxScroll) {
          scrollRef.current.scrollLeft = 0;
        }
      }
    };

    const scrollByOneCard = () => {
      if (scrollRef.current) {
        const { scrollLeft } = scrollRef.current;
        const nextScrollLeft =
          Math.round(scrollLeft / cardWidth) * cardWidth + cardWidth;

        scrollRef.current.scroll({
          left: nextScrollLeft,
          behavior: 'smooth',
        });
      }
    };

    const interval = setInterval(() => {
      if (!isHovered && scrollRef.current) {
        scrollByOneCard();
      }
    }, 3000); // Scroll every 10 seconds

    scrollRef.current.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(interval);
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isHovered]);

  return (
    <div className="bg-black h-screen px-5 py-5 lg:px-10">
      <div className="flex justify-between items-center lg:pr-10">
        <div>
          <h5 className="font-lato text-white font-[700] text-[22px] lg:text-[1.5em]">
            We’ll help you get started
          </h5>
          <p className="font-lato py-2 text-[#8D8E8E] text-[14px] lg:text-[18px]">
            We only write blogs that actually help you make better decisions.
          </p>
        </div>
        {/* <div className="hidden lg:flex gap-x-2">
          <div
            className={`bg-white cursor-pointer w-[62px] flex items-center justify-center h-[62px] rounded-full`}
            onClick={scrollLeft}
          >
            <img src={ArrowLeft} className="w-[21px]" alt="Scroll Left" />
          </div>
          <div
            className={`bg-white cursor-pointer w-[62px] flex items-center justify-center h-[62px] rounded-full`}
            onClick={scrollRight}
          >
            <img src={ArrowRight} className="w-[21px]" alt="Scroll Right" />
          </div>
        </div> */}
      </div>

      {/* Desktop view: Static view */}
      <div className="hidden mt-5 lg:flex gap-x-4 w-full items-center justify-start overflow-x-scroll no-scrollbar">
        {blogs.map(blog => {
          return (
            <BlogCard
              image={blog._embedded['wp:featuredmedia']['0'].source_url}
              title={blog.title.rendered}
              description={blog.content.rendered}
              link={blog.link}
              date={blog.date}
            />
          );
        })}
      </div>

      {/* Mobile view: Auto-scroll and manual scroll */}
      <div
        className="flex lg:hidden mt-10 w-full items-center justify-start overflow-x-scroll no-scrollbar snap-x snap-mandatory"
        ref={scrollRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {blogs.map(blog => {
          return (
            <div className="snap-center shrink-0 w-full">
              <BlogCard
                image={blog._embedded['wp:featuredmedia']['0'].source_url}
                title={blog.title.rendered}
                description={blog.content.rendered}
                link={blog.link}
                date={blog.date}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
