import users from '../../assets/svgs/users.svg';
import stars from '../../assets/svgs/stars.svg';
import buildAtScale from '../../assets/animations/animation.mp4';
import buildAtScaleMobile from '../../assets/animations/animationMobile.mp4';
import Navbar from '../common/navbar';
import ClientSlider from './clientSlider';
import { useEffect, useRef, useState } from 'react';
import { FullpageContext } from '@ap.cx/react-fullpage';

export default function Hero({ scrollToContact }) {
  const videoRef = useRef(null);
  const [contactSlideIndex, setContactSlideIndex] = useState(8);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');

    const updateSlideIndex = e => {
      if (e.matches) {
        setContactSlideIndex(10);
      } else {
        setContactSlideIndex(8);
      }
    };

    updateSlideIndex(mediaQuery);

    mediaQuery.addEventListener('change', updateSlideIndex);

    return () => {
      mediaQuery.removeEventListener('change', updateSlideIndex);
    };
  }, []);

  return (
    <div className="relative h-screen bg-black">
      <Navbar />
      <div className="relative flex flex-col lg:flex-row bg-black mb-6 overflow-hidden">
        <div className="relative w-full lg:h-[70vh] flex flex-col z-10">
          <video
            ref={videoRef}
            src={buildAtScale}
            autoPlay
            muted
            playsInline
            preload="auto"
            className="hidden lg:block absolute mt-[4vh] left-0 w-full lg:h-[20vh] object-cover"
          ></video>
          <video
            ref={videoRef}
            src={buildAtScaleMobile}
            autoPlay
            muted
            playsInline
            preload="auto"
            className="block lg:hidden left-0 scale-150 h-[12vh] mt-10 object-cover"
          ></video>
          <div className="mt-10 lg:mt-[26vh] flex flex-col items-center gap-y-5">
            <p className="text-white text-center w-3/4 font-[400] text-[1.5em] lg:text-[2em] font-lato">
              Crafting digital solutions for the{' '}
              <span className="text-[#24FFFF] font-[700]">Fintech</span>,{' '}
              <span className="text-[#24FFFF] font-[700]">Healthcare</span> &{' '}
              <span className="text-[#24FFFF] font-[700]">Ecommerce</span>{' '}
              sector with care and integrity.
            </p>

            <div className="flex flex-col gap-y-4 lg:flex-row items-center justify-center lg:justify-start lg:gap-x-4">
              <img src={users} alt="Users" />
              <img src={stars} alt="Stars" />
              <p className="font-lato font-[800] text-white">
                4.4/5{' '}
                <span className="font-lato text-white font-[400]">
                  (13k+ Reviews)
                </span>
              </p>
            </div>
            <FullpageContext.Consumer>
              {ctx => (
                <button
                  onClick={() => ctx.goto(ctx.slides[contactSlideIndex], false)}
                  className="bg-primary mx-auto lg:mx-0 w-fit text-white text-[0.75em] lg:text-[1em] font-lato px-8 py-2 rounded-[30px] font-[500]"
                >
                  Contact us
                </button>
              )}
            </FullpageContext.Consumer>
          </div>
        </div>
      </div>
      <ClientSlider />
    </div>
  );
}
