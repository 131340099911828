import ContactForm from '../../atoms/home/contactForm';
import NextSteps from '../../atoms/home/nextStepsDesktop';

export default function Contact() {
  return (
    <div
      id="contact"
      className="px-5 py-5 h-screen lg:px-10 bg-black flex flex-col"
    >
      <h5 className="font-lato text-white font-[700] text-[22px] lg:text-[1.5em]">
        Contact Us
      </h5>
      <p className="text-textColor2 py-2 font-lato text-[14px] lg:text-[1em]">
        Fill out the form below and we’ll get back to you once we’ve processed
        your request.
      </p>
      <div className="flex flex-col mt-2 lg:flex-row lg:gap-x-10">
        <div className="lg:w-[45%]">
          <ContactForm />
        </div>
        <div className="lg:w-[50%]">
          <NextSteps />
        </div>
      </div>
    </div>
  );
}
