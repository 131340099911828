import quote from '../../assets/icons/quote.png';

export default function TestimonialCard({
  author,
  content,
  username,
  active,
  authorImage,
  authorDesignation,
}) {
  return (
    <div className="relative h-screen px-[10px]">
      <div className="w-[400px] h-[300px] bg-white px-10 py-5 rounded-lg">
        <div className="">
          <img src={quote} className="h-[60px]"></img>
        </div>
        <div className="text-[#61646b] mt-4 text-[1em]">"{content}"</div>
      </div>
      <div className="absolute z-50 top-[270px] left-[20px] flex flex-col gap-y-2">
        <img
          src={authorImage}
          className="h-[80px] w-[80px] bg-blue-500 rounded-full"
        ></img>
        <p className="text-white text-[1em]">{author}</p>
        <p className="text-white text-gray-300">{authorDesignation}</p>
      </div>
    </div>
  );
}
