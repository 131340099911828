import { useState } from 'react';
import ArrowRightBlack from '../../assets/icons/arrowRightBlack.svg';

export default function Storyline() {
  const [activeSection, setActiveSection] = useState(0);

  const sections = [
    {
      title: `Start here`,
      content: `At BuildAtScale, we do things a little differently. If you're looking for quick turnarounds, short deadlines, and ultra-low budgets, we may not be the right fit. We're a slow agency that thrives on long-term partnerships, with clients who understand that quality work takes time, dedication, and investment. We believe in building for the long haul, and that means we often say no to projects that don’t align with this vision.`,
    },
    {
      title: `Small Team, Big Impact`,
      content: `We’re a tight-knit, dedicated team that puts heart and focus into everything we do. Our size allows us to stay agile and hands-on with every project, delivering tailored, high-quality work with a personal touch.`,
    },
    {
      title: `Value Over Profit`,
      content: `Yes, we’re a business, and profitability matters. But we’re not interested in chasing short-term gains at the expense of quality or our team’s well-being. We don’t compete with freelancers on price because we believe in fairly compensating our team for the care and integrity they bring to every project. We charge based on the value we create, not by the hour.`,
    },
    {
      title: `More Than an Agency`,
      content: `Behind the scenes, we’re a lot like you. We have our own ideas, we’re experimenting, building products, and dreaming of future innovations. While our agency helps keep the lights on, our true passion is creating software and platforms that have a lasting impact.`,
    },
    {
      title: `Weekends Are Sacred`,
      content: `We believe in balance. Urgency on your part doesn’t create an emergency on ours. We take weekends off to recharge so we can be at our best for you during the week.`,
    },
    {
      title: `Our Ideal Partners`,
      content: `We choose to collaborate with medium and large businesses that value respect, healthy boundaries, and mutual growth. We avoid working with corporations that compromise the well-being of their teams, as we believe in creating positive, supportive partnerships.`,
    },
    {
      title: `Remote Work, Real Results`,
      content: `We work remotely, allowing us the flexibility to deliver our best work from anywhere. Distance doesn’t dilute quality; it enhances focus.`,
    },
    {
      title: `Meetings Don’t Equal Progress`,
      content: `Just because we’re not flooding your inbox or WhatsApp doesn’t mean we’re not making progress. We believe in focusing on tasks, not unnecessary updates. You’ll hear from us when there’s something meaningful to share.`,
    },
    {
      title: `Trust the Process`,
      content: `We’ve done this countless times, and we’ve honed a process that works. While it’s tempting to tweak things along the way, trust our expertise and stick to the plan—we promise it leads to better results.`,
    },
    {
      title: `Quality Needs Calm`,
      content: `Good work requires focus and peace of mind. We prioritize working in a calm, distraction-free environment so we can give your project the attention it deserves.`,
    },
    {
      title: `Scope Creep? Know the Difference`,
      content: `Expanding a project’s scope while it's in progress is common but often leads to delays. Understand the difference between what was agreed upon and what’s being added mid-way to avoid frustrations later.`,
    },
    {
      title: `Change Requests Aren’t Bugs`,
      content: `Want to adjust the UI or add more screens? That’s great, but let’s call them what they are—change requests, not bugs. They’re important, but they’re also additions that need to be managed separately.`,
    },
    {
      title: `It’s Not Easy`,
      content: `“Easy” is a word that oversimplifies what others do. Be mindful when discussing tasks that aren’t your expertise. We respect your craft; please respect ours.`,
    },
    {
      title: `buildatscale.`,
      content: `We connect with people, try to build their dreams into realities. We hire people, try to build their job into a fulfilling career. We envision doing it at scale while keeping it small at the same time.`,
    },
  ];

  const handleNextSection = () => {
    if (activeSection !== sections.length - 1) {
      setActiveSection(activeSection + 1);
    } else {
      setActiveSection(0);
    }
  };

  return (
    <div className="relative font-labGrotesque no-scrollbar h-screen overflow-y-auto bg-black flex flex-col gap-y-1 px-4 pt-20 pb-10 lg:px-10 lg:py-10">
      {sections.map((section, index) => (
        <div key={index} className="flex flex-col gap-y-2 lg:gap-y-0">
          <div
            className="cursor-pointer hover:text-white overflow-y-auto text-[22px] lg:text-[2em]"
            onClick={() => setActiveSection(index)}
          >
            <div
              className={`flex gap-x-2 text-[1em] hover:text-white ${activeSection === index ? 'text-white' : 'text-gray-500'}`}
            >
              <div className="w-[10%] lg:w-[4%] inline-block">
                {index <= 9 ? `0${index}.` : `${index}.`}
              </div>{' '}
              <div className="w-[90%] lg:w-[96%]">{section.title}</div>
            </div>
          </div>
          {activeSection === index && (
            <div className="pl-12 lg:pl-14">
              <h3 className="text-white lg:w-[80%] font-[200] text-[1em] lg:text-[1.5em]">
                {section.content}
              </h3>
            </div>
          )}
        </div>
      ))}

      {/* Floating button */}
      <div
        onClick={handleNextSection}
        className="z-10 cursor-pointer rounded-full p-2 bg-white fixed mt-[70vh] lg:mt-[35%] right-10 w-[50px] h-[50px] flex items-center justify-center"
      >
        <img src={ArrowRightBlack} alt="Next section"></img>
      </div>
    </div>
  );
}
