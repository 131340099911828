import { useRef } from 'react';
import Calendar from '../atoms/home/calendar';
import Clients from '../atoms/home/clients';
import NextSteps from '../atoms/home/nextStepsDesktop';
import NextStepsMobile from '../atoms/home/nextStepsMobile';
import About from '../components/home/about';
import Blog from '../components/home/blog';
import Chat from '../components/home/chat';
import Contact from '../components/home/contact';
import CTA from '../components/home/cta';
import Footer from '../components/home/footer';
import Hero from '../components/home/hero';
import Service from '../components/home/service';
import Storyline from '../components/home/storyline';
import Testimonials from '../components/home/testimonials';
import {
  Fullpage,
  FullPageSections,
  FullpageSection,
} from '@ap.cx/react-fullpage';

export default function Home() {
  return (
    <Fullpage>
      <FullPageSections>
        <FullpageSection>
          <Hero />
        </FullpageSection>
        <FullpageSection>
          <Storyline />
        </FullpageSection>
        <FullpageSection>
          <About />
        </FullpageSection>
        <FullpageSection>
          <Clients />
        </FullpageSection>
        <FullpageSection>
          <Service />
        </FullpageSection>
        <FullpageSection>
          <Testimonials />
        </FullpageSection>
        <FullpageSection>
          <Calendar />
        </FullpageSection>
        <FullpageSection>
          <Blog />
        </FullpageSection>
        <FullpageSection>
          <Contact />
        </FullpageSection>
        <div className="block lg:hidden">
          <FullpageSection>
            <NextStepsMobile />
          </FullpageSection>
        </div>
        <div className="block lg:hidden">
          <FullpageSection>
            <Chat />
          </FullpageSection>
        </div>
        <FullpageSection>
          <Footer />
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}
