import Navbar from '../components/common/navbar';
import InstagramProfileEmbed from '../components/fame/instaEmbed';

export default function WallOfFame() {
  return (
    <div>
      <Navbar />
      <div className="mt-5 px-5 lg:mt-10 lg:px-24">
        <InstagramProfileEmbed />
      </div>
    </div>
  );
}
