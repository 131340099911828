import BlogImage from '../../assets/images/blogImage.png';
import ArrowRightBlack from '../../assets/icons/arrowRightBlack.svg';
import { useNavigate } from 'react-router-dom';

export default function BlogGridCard() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate('/blog/1/');
      }}
      className="cursor-pointer rounded-[20px] px-4 py-4 lg:px-6 lg:py-6 w-fit lg:w-[450px] flex flex-col gap-y-3 lg:gap-y-4 border-[1px] border-borderColorCardGrid flex-shrink-0"
    >
      <img src={BlogImage}></img>
      <h6 className="font-lato text-[14px] lg:text-[22px] font-[700]">
        The 2024 state of design
      </h6>
      <p className="font-lato text-textColor text-[12px] lg:text-[16px] font-[350]">
        Some content given by the client here Some content given by the client
        here Some content given by the client here Some content given by the
        client here{' '}
      </p>
      <button className="flex gap-x-4 items-center">
        <p className="text-[12px] lg:text-[16px]">Read more</p>
        <img src={ArrowRightBlack} className="w-[12px] lg:w-[15px]"></img>
      </button>
    </div>
  );
}
