export default function NextStepsMobile() {
  return (
    <div className="block lg:hidden bg-black h-screen relative flex flex-col lg:hidden px-5 py-5 lg:py-10 px-10">
      <h6 className="font-lato text-[18px] text-white font-[600]">
        What Happens Next ?
      </h6>
      <div className="flex flex-col gap-y-5 mt-5 px-5">
        <div className="relative">
          <div className="absolute top-[40%] font-lato left-[-6%] bg-primary text-white rounded-[10px] p-2 w-[40px] font-lato text-[1em] flex justify-center">
            1
          </div>
          <div className="rounded-[10px] text-[0.8em] min-h-[90px]  h-fit font-lato flex items-center text-white border-[1px] border-primary pl-10 p-4">
            Having received and processed your request, we will get back to you
            shortly to detail your project needs and sign an NDA to ensure the
            confidentiality of information.
          </div>
        </div>
        <div className="relative">
          <div className="absolute top-[40%] font-lato left-[-6%] bg-primary text-white rounded-[10px] p-2 w-[40px] font-lato text-[1em] flex justify-center">
            2
          </div>
          <div className="rounded-[10px] min-h-[90px] text-[0.8em] font-lato text-white  h-fit flex items-center border-[1px] border-primary pl-10 p-4">
            After examining requirements, our analysts and developers devise a
            project proposal with the scope of works, team size, time and cost
            estimates.
          </div>
        </div>
        <div className="relative">
          <div className="absolute top-[40%] font-lato left-[-6%] bg-primary text-white rounded-[10px] p-2 w-[40px] font-lato text-[1em] flex justify-center">
            3
          </div>
          <div className="rounded-[10px] min-h-[90px] text-[0.8em] font-lato text-white  h-fit flex items-center border-[1px] border-primary pl-10 p-4">
            We arrange a meeting with you to discuss the offer and come to an
            agreement.
          </div>
        </div>
        <div className="relative">
          <div className="absolute top-[40%] font-lato left-[-6%] bg-primary text-white rounded-[10px] p-2 w-[40px] font-lato text-[1em] flex justify-center">
            4
          </div>
          <div className="rounded-[10px] min-h-[90px] text-[0.8em] font-lato text-white h-fit flex items-center border-[1px] border-primary pl-10 p-4">
            We sign a contract and start working on your project as quickly as
            possible.
          </div>
        </div>
      </div>
    </div>
  );
}
