export default function Card({
  borderColor,
  textColor,
  backgroundColor,
  cardText,
}) {
  return (
    <div
      className={`cursor-pointer rounded-md w-[17em] lg:w-[20em] px-5 lg:py-14 lg:px-10 font-lato border-[1px] ${borderColor} ${backgroundColor} ${textColor}`}
    >
      <h6 className={`uppercase text-[1em] ${textColor}`}>{cardText.text1}</h6>
      <div className={`flex flex-col mt-16 ${textColor}`}>
        <p className={`text-left ${textColor}`}>{cardText.text2}</p>
        <h5 className="font-[600] text-[2.5em]">
          &gt;{cardText.text3}
          <br />
          {cardText.text4}
        </h5>
      </div>
      <p className={`lowercase text-[1em] py-4 lg:py-0 ${textColor}`}>
        {cardText.text5}
      </p>
    </div>
  );
}
