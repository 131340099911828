import React, { useState, useEffect, useRef } from 'react';
import TestimonialCard from '../../atoms/home/testimonialCard';
import Tom from '../../assets/images/testimonials/tom.jpeg';
import David from '../../assets/images/testimonials/david.jpeg';
import Santosh from '../../assets/images/testimonials/santosh.jpeg';
import Emily from '../../assets/images/testimonials/emily.jpeg';
import Mark from '../../assets/images/testimonials/mark.jpeg';
import Michael from '../../assets/images/testimonials/michael.jpeg';
import Jessica from '../../assets/images/testimonials/jessica.jpeg';

const testimonials = [
  {
    id: 1,
    content:
      'The team at BuildAtScale delivered a stunning website for our business. Their attention to detail and ability to bring our vision to life exceeded all expectations. We now have a website that not only looks great but performs flawlessly.',
    author: 'Santosh M.',
    designation: 'Founder',
    image: Santosh,
  },
  {
    id: 2,
    content:
      'We needed a high-converting landing page, and BuildAtScale nailed it! The design was clean, and the user experience was seamless. Our conversion rates have skyrocketed since launch.',
    author: 'David L.',
    designation: 'Marketing Manager',
    image: David,
  },
  {
    id: 3,
    content:
      'BuildAtScale developed a user-friendly app for our brand, and we couldn’t be happier. Their team was with us from concept to launch, ensuring the app met our every requirement. The feedback from our users has been overwhelmingly positive.',
    author: 'Michael K.',
    designation: 'Product Lead',
    image: Michael,
  },
  {
    id: 4,
    content:
      'Our SaaS product is a game-changer, thanks to BuildAtScale. Their expertise in building scalable, robust platforms made all the difference. They were attentive, innovative, and a pleasure to work with throughout the development process.',
    author: 'Mark R.',
    designation: 'CEO',
    image: Mark,
  },
  {
    id: 5,
    content:
      'BuildAtScale helped us transform our digital marketing strategy. Their performance marketing team crafted highly targeted campaigns that brought in qualified leads and significantly boosted our ROI.',
    author: 'Jessica P.',
    designation: 'Head of Marketing',
    image: Jessica,
  },
  {
    id: 6,
    content:
      'From start to finish, BuildAtScale’s website development process was smooth and efficient. They created a site that perfectly reflects our brand, and we’ve seen a notable improvement in user engagement and traffic..',
    author: 'Emily G.',
    designation: 'Creative Director',
    image: Emily,
  },
  {
    id: 7,
    content:
      'Our mobile app has been a hit with users, and we owe it to the amazing work by BuildAtScale. The team was flexible, innovative, and deeply committed to delivering a product we’re proud of.',
    author: 'Tom S.',
    designation: 'Co-Founder',
    image: Tom,
  },
];

export default function Testimonials() {
  const containerRef = useRef(null);
  const cardWidth = 420; // Width of each card in pixels
  const [isHovered, setIsHovered] = useState(false); // To pause on hover
  const [hasScrolledOnce, setHasScrolledOnce] = useState(false); // Track if scrolled once

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = containerRef.current;
        const maxScroll = scrollWidth - clientWidth;

        // If reached the end, scroll back to the start
        if (scrollLeft >= maxScroll) {
          containerRef.current.scrollLeft = 0;
        }
      }
    };

    const scrollByOneCard = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        const nextScrollLeft =
          Math.round(scrollLeft / cardWidth) * cardWidth + cardWidth;

        containerRef.current.scroll({
          left: nextScrollLeft,
          behavior: 'smooth',
        });

        // Stop further automatic scrolling after the first scroll
        setHasScrolledOnce(true);
      }
    };

    if (!hasScrolledOnce) {
      const interval = setInterval(() => {
        if (!isHovered && containerRef.current) {
          scrollByOneCard();
        }
      }, 3000); // Scroll after 3 seconds

      containerRef.current.addEventListener('scroll', handleScroll);

      return () => {
        clearInterval(interval);
        if (containerRef.current) {
          containerRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [isHovered, hasScrolledOnce]);

  return (
    <div className="relative h-screen w-full bg-black overflow-hidden px-5 py-5 lg:px-10 lg:py-10">
      <p className="text-white font-lato text-[22px] lg:text-[1.5em] font-[600]">
        Real people. Real reviews.
      </p>
      <p className="font-lato py-2 text-textColor lg:text-[18px] font-[400]">
        See what our clients have to say about working with us.
      </p>
      <div
        className="relative mt-10 flex justify-between"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          ref={containerRef}
          className="flex overflow-x-auto scroll-smooth"
          style={{ scrollBehavior: 'smooth' }}
        >
          {/* Map twice to create an infinite scrolling effect */}
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <TestimonialCard
              key={index}
              author={testimonial.author}
              content={testimonial.content}
              username={testimonial.id}
              authorDesignation={testimonial.designation}
              authorImage={testimonial.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
